<template>
<div class="cert-user">
  <div class="cert-user-top">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="cert-user-cont">
    <div class="cert-user-cont-top">
      <p>物料管理</p>
      <div>
<!--        <Upload :action="constant.URL + '/uploadfile/upload'"-->
<!--                :data="{-->
<!--                      type:0-->
<!--                    }"-->
<!--                accept=".zip"-->
<!--                :headers="{-->
<!--                      'access-token':Cookies.get('liteAccessToken')-->
<!--                    }"-->
<!--                :on-success="uploadFileSuccess"-->
<!--        >-->
<!--          <Button type="primary">批量导入</Button>-->

<!--        </Upload>-->


        <Button type="primary" @click="batchUpload">批量导入</Button>
        <Button type="primary" @click="showNotice">批量短信通知</Button>
        <Button type="primary" @click="exportNames">导出名单</Button>
      </div>
    </div>
    <div class="cont-search">
      <div class="search">
        <div>
          <p class="label">类型筛选：</p>
          <div class="cont">
            <p :class="item.id == type ? 'active' : ''" v-for="(item,index) in typeList" @click="changeSearch(item.id,'type')" :key="index+'active1'">{{ item.name }}</p>
          </div>
        </div>
        <div>
          <p class="label">状态：</p>
          <div class="cont">
            <p :class="item.id == receiveStatus ? 'active' : ''" v-for="(item,index) in receiveStatusList" @click="changeSearch(item.id,'receiveStatus')" :key="index+'active2'">{{ item.name }}</p>
          </div>
        </div>
        <div>
          <p class="label">发送状态：</p>
          <div class="cont">
            <p :class="item.id == status ? 'active' : ''" v-for="(item,index) in statusList" @click="changeSearch(item.id,'status')" :key="index+'active3'">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <Input v-model="keyword"
             placeholder="输入手机号/ID搜索"
             clearable
             :enter-button="true"
             @on-enter="changeKeyword"
             @on-clear="changeKeyword"
             @on-blur="changeKeyword"
             style="width: 200px">
        <div slot="suffix" class="search-img">
          <img  src="../../assets/images/new_icon/sousuo.png" width="18" height="18" />
        </div>
      </Input>
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
  <!-- <Modal
      v-model="noticeModal"
      title="短信通知"
      footer-hide
      width="1200px"
  >
    <div class="table">
      <Table border :columns="noticeColumns" :data="noticeDataList" :loading="noticeLoading"></Table>
    </div>
    <div class="page">
      <MyPage :total="noticeTotal" :current="noticePage" @changePage="changeNoticePage" @changePageSize="changeNoticePageSize"></MyPage>
    </div>
  </Modal> -->
  <Modal
      v-model="editModal"
      title="编辑"
      width="600px"
  >
    <div class="search mt20">
      <div >
        <p class="label" style="width: 140px;text-align: right">批次名称：</p>
        <div class="cont">
          <Input v-model="editData.name" placeholder="请输入批次名称" style="width: 300px" />
        </div>
      </div>
    </div>
    <div class="search mt20" v-if="batchCurData.type == 3">
      <div>
        <p class="label" style="width: 140px;text-align: right">有效期截止时间：</p>
        <div class="cont">
          <DatePicker type="date" v-model="editData.expire_time" :options="dateOptions"  format="yyyy-MM-dd" placeholder="请选择有效期截止时间" style="width: 300px"></DatePicker>
        </div>
      </div>
    </div>


    <div slot="footer">
      <Button type="primary" @click="confirmEdit">确定</Button>
    </div>
  </Modal>
  <!-- <Modal
      v-model="uploadModal"
      title="上传文件"
      width="600px"
  >

    <div class="search" style="margin-top: 20px">
      <div>
        <p class="label">选择类型：</p>
        <div class="cont">
          <p :class="item.id == uploadType ? 'active' : ''" v-for="(item,index) in uploadTypeList" :key="index+'active4'" @click="changeModalType(item.id,'uploadType')">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="search mt20">
      <div >
        <p class="label" style="width: 140px;text-align: right">设置批次名称：</p>
        <div class="cont">
          <Input v-model="batchName" placeholder="请输入批次名称" style="width: 300px" />
        </div>
      </div>
    </div>
    <div class="search mt20" v-if="uploadType == 3">
      <div>
        <p class="label" style="width: 140px;text-align: right">选择有效期截止时间：</p>
        <div class="cont">
          <DatePicker type="date" v-model="expireTime" :options="dateOptions"  format="yyyy-MM-dd" placeholder="请选择有效期截止时间" style="width: 300px"></DatePicker>
        </div>
      </div>
    </div>
    <Upload :action="constant.URL + '/uploadfile/upload'"
            :data="{
                      type:0
                    }"
            accept=".zip,.xls,.xlsx"
            :headers="{
                      'access-token':Cookies.get('liteAccessToken')
                    }"
            :on-success="uploadFileSuccess"
            :before-upload="beforeUpload"
            :show-upload-list="false"
    >
      <div style="margin: 20px 0">
        <Button type="primary" :loading="uploadLoading">上传文件</Button>
        <span style="margin-left: 20px">{{uploadName}}</span>
      </div>

    </Upload>
    <Button type="text" v-if="uploadType == 3" @click="downTemplete">下载模版</Button>
    <p v-else>文件命名请遵循：手机号-类型-日期（例：15010720419-结业-20230405）</p>

    <div slot="footer">
      <Button type="primary" :loading="confirmUploadLoading" @click="confirmUpload">确定</Button>
    </div>
  </Modal> -->
  <!-- <Modal
      v-model="exportNamesModal"
      title="导出名单"
      width="600px"
  >
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
      <FormItem label="选择导出的类型" prop="type">
        <RadioGroup v-model="formValidate.type" @on-change="changeType">
          <Radio :label="item.id" v-for="item in uploadTypeList" :key="item.id">{{ item.name }}</Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="选择批次" prop="import_id" v-if="formValidate.type">
        <Select v-model="formValidate.import_id" placeholder="请选择批次">
          <Option :value="item.id" v-for="item in batchList" :key="item.id">{{ item.name }}(ID:{{item.id}})</Option>
        </Select>
      </FormItem>
      <FormItem label="选择导出的状态" prop="receive_status">
        <RadioGroup v-model="formValidate.receive_status">
          <Radio :label="item.id" v-for="item in receiveStatusModalList" :key="item.id">{{ item.name }}</Radio>
        </RadioGroup>
      </FormItem>
    </Form>

    <div slot="footer">
      <Button type="primary" @click="confirmExport">确定导出</Button>
    </div>
  </Modal> -->
  <Modal
      v-model="receiveModal"
      title="查看物料"
      :footer-hide="true"
  >
    <p style="font-size: 14px;margin-bottom: 20px">您的考试码为：{{ receiveInfo.data }}</p>
    <Button type="primary" @click="copy">复制考试码</Button>
  </Modal>
  <!-- 批量导入抽屉 -->
  <el-drawer :size="800" :visible.sync="uploadModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">上传文件</div>
      <div class="drawer-title-btns">
        <Button type="primary" :loading="confirmUploadLoading" @click="confirmUpload">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="search" style="margin-top: 20px">
      <div>
        <p class="label">选择类型：</p>
        <div class="cont">
          <p :class="item.id == uploadType ? 'active' : ''" v-for="(item,index) in uploadTypeList" :key="index+'active4'" @click="changeModalType(item.id,'uploadType')">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="search mt20">
      <div >
        <p class="label" style="width: 140px;text-align: right">设置批次名称：</p>
        <div class="cont">
          <Input v-model="batchName" placeholder="请输入批次名称" style="width: 300px" />
        </div>
      </div>
    </div>
    <div class="search mt20" v-if="uploadType == 3">
      <div>
        <p class="label" style="width: 140px;text-align: right">选择有效期截止时间：</p>
        <div class="cont">
          <DatePicker type="date" v-model="expireTime" :options="dateOptions"  format="yyyy-MM-dd" placeholder="请选择有效期截止时间" style="width: 300px"></DatePicker>
        </div>
      </div>
    </div>
    <Upload :action="constant.URL + '/uploadfile/upload'"
            :data="{
                      type:0
                    }"
            accept=".zip,.xls,.xlsx"
            :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
            :on-success="uploadFileSuccess"
            :before-upload="beforeUpload"
            :show-upload-list="false"
    >
      <div style="margin: 20px 0">
        <Button type="primary" :loading="uploadLoading">上传文件</Button>
        <span style="margin-left: 20px">{{uploadName}}</span>
      </div>

    </Upload>
    <Button type="text" v-if="uploadType == 3" @click="downTemplete">下载模版</Button>
    <p v-else>文件命名请遵循：手机号-类型-日期（例：15010720419-结业-20230405）</p>
    </div>
  </el-drawer>
  <!-- 短信通知抽屉 -->
  <el-drawer :size="1200" :visible.sync="noticeModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">短信通知</div>
    </div>
    <div style="padding: 20px;">
      <div class="table">
        <Table border :columns="noticeColumns" :data="noticeDataList" :loading="noticeLoading"></Table>
      </div>
      <div class="page">
        <MyPage :total="noticeTotal" :current="noticePage" @changePage="changeNoticePage" @changePageSize="changeNoticePageSize"></MyPage>
      </div>
    </div>
  </el-drawer>
  <!-- 导出名单抽屉 -->
  <el-drawer :size="800" :visible.sync="exportNamesModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">导出名单</div>
      <div class="drawer-title-btns">
        <Button type="primary" @click="confirmExport">确定导出</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="选择导出的类型" prop="type">
          <RadioGroup v-model="formValidate.type" @on-change="changeType">
            <Radio :label="item.id" v-for="item in uploadTypeList" :key="item.id">{{ item.name }}</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="选择批次" prop="import_id" v-if="formValidate.type">
          <Select v-model="formValidate.import_id" placeholder="请选择批次">
            <Option :value="item.id" v-for="item in batchList" :key="item.id">{{ item.name }}(ID:{{item.id}})</Option>
          </Select>
        </FormItem>
        <FormItem label="选择导出的状态" prop="receive_status">
          <RadioGroup v-model="formValidate.receive_status">
            <Radio :label="item.id" v-for="item in receiveStatusModalList" :key="item.id">{{ item.name }}</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
    </div>
  </el-drawer>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
// import FileSaver from "file-saver";
export default {
  name: "list",
  data(){
    return {
      Cookies:Cookies,
      columns:[
        {
          title: 'ID',
          key: 'id',
          width:80
        },
        {
          title: '手机号',
          minWidth:100,
          render:(h,params)=>{
            return h('p',params.row.mobile)
          }
        },
        {
          title: '姓名',
          minWidth:100,
          render:(h,params)=>{
            return h('p',params.row.nickname + '(ID:' + params.row.user_id + ')')
          }
        },
        {
          title: '组织名称',
          key:'site_name',
          minWidth:100,
        },
        {
          title: '组织区域',
          minWidth:100,
          render:(h,params)=>{
            let arr = [];
            if(params.row.site_area.province){
              arr.push(params.row.site_area.province.name)
            }
            if(params.row.site_area.city){
              arr.push(params.row.site_area.city.name)
            }
            return h('div', {
            }, arr.length ? arr.join('-') : '');
          }
        },
        {
          title: '类型',
          minWidth:100,
          render:(h,params)=>{
            return h('p',this.types[params.row.type])
          }
        },
        {
          title: '批次名称',
          key:'import_name',
          minWidth:100,
        },
        {
          title: '有效期至',
          minWidth:100,
          key:'expire_time',
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.expire_time * 1000),
                'yyyy-MM-dd hh:mm'
            ) || '-')
          }
        },
        {
          title: '下载/领取次数',
          key:'downloadCount',
          minWidth:100,
        },
        // {
        //   title: '物料地址',
        //   key: 'cert_pdf',
        // },
        // {
        //   title: '下载PDF次数',
        //   render:(h,params)=>{
        //     return h('p', params.row.downloadPdfCount )
        //   }
        // },
        // {
        //   title: '下载图片次数',
        //   render:(h,params)=>{
        //     return h('p', params.row.downloadImgCount )
        //   }
        // },
        {
          title: '领取状态',
          minWidth:100,
          render:(h,params)=>{
            return h('p', params.row.receive_time > 0 ? '已领取' : '未领取' )
          }
        },
        {
          title: '短信发送状态',
          key: 'name',
          minWidth:100,
          render:(h,params)=>{
            return h('p', this.statuses[params.row.status] )
          }
        },
        {
          title: '导入日期',
          key: 'name',
          minWidth:100,
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.created_at * 1000),
                'yyyy-MM-dd hh:mm'
            ))
          }
        },
        {
          title: '操作',
          width:200,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  marginTop: '5px',
                  marginRight:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    if(params.row.type == 3){
                      this.showReceive();
                    }else{
                      this.checkFile();
                    }

                  }
                }
              }, '查看物料'),
              params.row.status == 0 ? h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  marginTop: '5px',
                  marginLeft:'0px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.notice();
                  }
                }
              }, '短信通知') : '',

              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:""
                },
                style:{
                  width:"75px",
                  marginTop:"5px"
                },
                on:{
                  change:(event)=>{
                    if(event==="下载"){
                      this.curData = params.row;
                      this.downLoad(this.curData.cert_pdf);
                    }else if(event==="删除"){
                      this.curData = params.row;
                      this.dele();
                    }
                  }
                }
              },[
                params.row.type != 3?h("el-option",{
                  props:{
                    value:"下载"
                  }
                },"下载"):"",
                params.row.status == 0?h("el-option",{
                  props:{
                    value:"删除"
                  },
                  style:{
                    color:"red"
                  }
                },"删除"):"",
              ])
            ]);
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      keyword:'',
      curStatusId:'',
      noticeModal:false,
      noticeColumns:[
        {
          title: 'ID',
          key: 'id',
          width:60,
        },
        {
          title: '批次名称',
          key: 'name'
        },
        {
          title: '有效期至',
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.expire_time * 1000),
                'yyyy-MM-dd hh:mm'
            ) || '-')
          }
        },
        {
          title: '证书数量',
          key: 'cert_num'
        },
        // {
        //   title: '待发送',
        //   key: 'no_send_num'
        // },
        {
          title: '待领取',
          key: 'no_receive_num',
          render:(h,params)=>{
            return h('p', params.row.status > 1 ? params.row.no_receive_num : '-' )
          }
        },
        {
          title: '已领取',
          render:(h,params)=>{
            return h('p', params.row.status > 1 ? (params.row.cert_num -  params.row.no_receive_num) : '0'  )
          }
        },
        {
          title: '发送状态',
          render:(h,params)=>{
            return h('p', this.noticeStatuses[params.row.status] )
          }
        },
        {
          title: '导入日期',
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.created_at * 1000),
                'yyyy-MM-dd hh:mm'
            ))
          }
        },
        {
          title: '操作',
          width:180,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.batchCurData = params.row;
                    this.editData.id = params.row.id;
                    this.editData.name = params.row.name;
                    this.editData.expire_time = util.timeFormatter(
                        new Date(+params.row.expire_time * 1000),
                        'yyyy-MM-dd hh:mm'
                    );
                    this.edit();
                  }
                }
              }, '编辑'),
              params.row.no_receive_num > 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.batchSend(params.row);
                  }
                }
              }, '批量发送短信') : h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:true
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {

                  }
                }
              }, this.noticeStatuses[params.row.status]),
            ]);
          }
        },
      ],
      noticeDataList:[],
      noticeLoading:false,
      noticeTotal:0,
      noticePage:1,
      noticePageSize:10,
      type:'-1',
      status:'-1',
      statusList:[],
      typeList:[],
      types: {},
      statuses:{},
      curData:{},
      noticeStatuses:{},
      uploadModal:false,
      uploadLoading:false,
      uploadType:'',
      uploadTypeList:[],
      receiveStatusList:[],
      receiveStatus:'-1',
      batchName:'',
      uploadfileId:'',  //上传的文件id
      uploadName:'',  //上传的文件名称
      expireTime:'',
      confirmUploadLoading:false,
      dateOptions:{
        disabledDate (date) {
          return date && date.valueOf() < Date.now();
        }
      },

      formValidate: {
        type: '',
        receive_status: '',
        import_id: '',
      },
      ruleValidate: {
        type: [
          { required: true, message: '请选择导出的类型', trigger: 'blur' }
        ],
        receive_status: [
          { required: true, message: '请选择批次', trigger: 'change' },
        ],
        import_id: [
          { required: true, message: '请选择导出的状态', trigger: 'change' }
        ],
      },
      exportNamesModal:false,
      batchList:[],
      receiveStatusModalList:[],
      templeteUrl:'',
      editModal:false,
      batchCurData:{},
      editData:{
        id:'',
        name:'',
        expire_time:'',
      },
      receiveModal:false,
      receiveInfo:{},
    }
  },
  created(){
    this.getList();
  },
  methods:{
    beforeUpload (){
      this.uploadLoading = true;
    },
    changeSearch(id,name){
      this[name] = id;
      this.getList();
    },
    getList(){
      let params = {
        site_id:'-1',
        user_id:'-1',
        type:this.type,
        status:this.status,
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize,
        receive_status:this.receiveStatus
      }
      this.api.certUser.datasetCertUserList(params).then((res)=>{
        this.dataList = res.list;
        this.statusList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name
          })
        }

        this.statuses = res.statuses;

        this.typeList = [{
          name:'全部',
          id:'-1'
        }];
        this.uploadTypeList = [];
        for(let name in res.types){
          this.typeList.push({
            name:res.types[name],
            id:name
          })
          this.uploadTypeList.push({
            name:res.types[name],
            id:name
          })
        }
        this.uploadType = this.uploadTypeList[0].id;
        this.types = res.types;

        this.receiveStatusList = [{
          name:'全部',
          id:'-1'
        }];
        this.receiveStatusModalList = [];
        for(let name in res.receive_statuses){
          this.receiveStatusList.push({
            name:res.receive_statuses[name],
            id:name
          })
          this.receiveStatusModalList.push({
            name:res.receive_statuses[name],
            id:name
          })
        }

        this.total = Number(res.count);
        this.templeteUrl = res.code_import_template;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    checkFile(){
      let path = this.constant.URL +'/uploadfile/show/?file=' + this.curData.cert_pdf;
      window.open(path,'_blank')
    },
    downLoad(data){
      let uploadParams = {
        file:data
      };
      this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
        util.downLoad(res)
      })
    },
    notice(){
      let params = {
        id:this.curData.id,
        site_id:-1,
        user_id:-1,
      };
      this.api.certUser.datasetCertUserSendSms(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    },
    dele(){
      let params = {
        id:this.curData.id,
        site_id:-1
      };
      this.api.certUser.batchSendCertDelete(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    },
    changeNoticePage(page){
      this.noticePage = page;
      this.getNoticeList();
    },
    changeNoticePageSize(pageSize){
      this.noticePage = 1;
      this.noticePageSize = pageSize;
      this.getNoticeList();
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.uploadfileId = response.data.info.id;
      this.uploadName = response.data.info.name;
      this.uploadLoading = false;
    },
    confirmUpload(){
      if(!this.batchName){
        this.$Message.warning({
          content:'请输入批次名称',
        });
        return;
      }
      if(this.uploadType == 3){ //考试码
        if(!this.expireTime){
          this.$Message.warning({
            content:'请选择有效期截止时间',
          });
          return;
        }
      }
      if(!this.uploadfileId){
        this.$Message.warning({
          content:'请上传文件',
        });
        return;
      }
      let params = {
        type:this.uploadType,
        uploadfile_id:this.uploadfileId ,
        name:this.batchName,
      }
      if(this.uploadType == 3){ //考试码
        params.expire_time = new Date(this.expireTime).getTime()/1000
      }

      this.confirmUploadLoading = true;
      this.api.certUser.datasetCertUserImport(params).then((res)=>{
        this.confirmUploadLoading = false;
        if(this.uploadType == 3){
          this.$Message.success({
            content:'上传成功',
            duration:3
          });
        }else{
          this.$Message.success({
            content:'上传成功,请等待3-10分钟',
            duration:3
          });
        }

        this.uploadModal = false;
      }).catch((err)=>{
        this.confirmUploadLoading = false;
      })
    },
    showNotice(){
      this.noticeModal = true;
      this.getNoticeList();
    },
    getNoticeList() {
      let params = {
        page: this.noticePage,
        pageSize: this.noticePageSize,
        site_id:-1
      }
      this.api.certUser.datasetCertUserImportList(params).then((res) => {
        this.noticeDataList = res.list;
        this.noticeTotal = +res.count;
        this.noticeStatuses = res.statuses;
      })
    },
    batchSend(data){
      let params = {
        id:data.id
      };
      this.api.certUser.batchSendCertSms(params).then((res)=>{
        this.$Message.success('发送成功');
        this.getNoticeList();
      })
    },
    batchUpload(){
      this.uploadModal = true;
      this.uploadType = '0';
      this.batchName = '';
      this.expireTime = '';
      this.uploadfileId = '';
      this.uploadName = '';
    },
    changeModalType(id){
      this.uploadType = id;
      this.batchName = '';
      this.expireTime = '';
      this.uploadfileId = '';
      this.uploadName = '';
    },
    downTemplete(){ //下载模版
      window.open( this.templeteUrl ,'_blank');
    },
    exportNames(){  //导出名单
      this.exportNamesModal = true;
      this.formValidate = {
        type:'',
        receive_status:'',
        import_id:'',
      }
    },
    changeType(){
      let params = {
        type:this.formValidate.type,
        page: 1,
        pageSize: 999,
        site_id:-1
      };
      this.api.certUser.datasetCertUserImportList(params).then((res) => {
        this.batchList = res.list;
      })
    },
    confirmExport(){  //确定导出
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            type:this.formValidate.type,
            receive_status:this.formValidate.receive_status,
            import_id:this.formValidate.import_id,
            site_id:-1
          };
          this.api.certUser.datasetCertUserExport(params).then((res)=>{
            let uploadParams = {
              file:res.filepath
            };
            this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
              util.downLoad(res);
              this.exportNamesModal = false;
            })
          })
        }
      })
    },
    edit(){
      this.editModal = true;
    },
    confirmEdit(){  //编辑确定
      console.log(this.editData.name,'this.editData.namethis.editData.name')
      if(!this.editData.name){
        this.$Message.warning('请输入批次名称');
        return;
      }
      if(this.editData.type == 3){
        if(!this.editData.expire_time){
          this.$Message.warning('有效期截止时间');
          return;
        }
      }


      let params = {
        id:this.editData.id,
        name:this.editData.name,
        expire_time:new Date(this.editData.expire_time).getTime()/1000
      };
      this.api.certUser.datasetCertUserImportUpdate(params).then((res)=>{
        this.editModal = false;
        this.getNoticeList();
      })
    },
    showReceive(){
      this.receiveModal = true;
      this.receive((data)=>{
        this.receiveInfo = data.info;
      })
    },
    receive(fn){
      let params = {
        id:this.curData.id,
        site_id:-1,
      }
      this.api.certUser.datasetCertUserDetail(params).then((res)=>{
        fn && fn(res);
      })
    },
    copy(){
      this.$copyText(this.receiveInfo.data).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
  }
}
</script>

<style scoped lang="scss">
.cert-user{
  .cert-user-top {
    margin: 20px;
  }
  .cert-user-cont{
    margin: 20px;
    padding: 20px;
    background-color: #FFFFFF;

    .cert-user-cont-top{
      height: 50px;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 16px;
        font-weight: bold;
      }
      >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >button{
          margin-left: 10px;
        }
      }
    }
    .cont-search{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-img{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }

}
.search{
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  >div{
    margin-right: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .label{
    font-weight: bold;
  }
  .cont{
    display: flex;
    justify-content: flex-start;
    >p{
      margin-right: 20px;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      color: #4A79FF;
    }
  }
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
